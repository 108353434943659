import React, { useState } from 'react'

import boxjSON from './Boxcards.json'
import './Boxcards.css'

function Boxcards() {
    const [boxContent, setBoxContent] = useState(boxjSON)

    return (
        <section className='dark--background box-cards-section bg--shape_down bg--shape_up'>
            <div className='outter-page'>
                <div className='inner-page'>
                    <h2 className='section-title text-white--color section-title--center'>About my skills</h2>
                    <div className='box-cards'>
                        {boxContent.map((boxData, i) => (
                            <div className='box-card' key={i}>
                                <div className='line-set first'></div>
                                <div className='line-set second'></div>
                                <div className='card-head'>
                                    <div className='card-icon'>
                                        <i className={boxData.boxIcon}></i>
                                    </div>
                                    <h3 className='card-title'>{boxData.boxTitle}</h3>
                                </div>
                                <p className='card-content'>{boxData.boxContent}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Boxcards