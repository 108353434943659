import React, { useState } from 'react'

import projectsjSON from './Projects.json'
import './Projects.css'

import Inputsearch from './Inputsearch'

function Projects() {
    const [filteredProject, setProject] = useState('')

    const projects = projectsjSON
        .filter(item => Object.values(item.projectTags).toString().toLowerCase().includes(filteredProject.toLowerCase())
            || item.projectTitle.toLowerCase().includes(filteredProject.toLowerCase()))
        .map((item, i) => (
            <div className='project-item' key={i}>
                <div className='project-img'>
                    <a href={item.projectLink} target='_blank' rel='noreferrer'>
                        <img src={`${item.projectImg}`} alt={item.projectTitle} />
                    </a>
                </div>
                <div className='project-details'>
                    <h3 className='title'>
                        <a href={item.projectLink} target='_blank' rel='noreferrer'>{item.projectTitle}</a>
                    </h3>
                    <p className='description'>{item.projectDescription}</p>
                    <div className='tags'>
                        {item.projectTags.map((tagsItem, i) => (
                            <span className='tag' key={i}>{tagsItem}</span>
                        ))}
                    </div>
                </div>
            </div>
        ))

    return (
        <section className='projects-section'>
            <div className='outter-page'>
                <div className='inner-page'>
                    <h2 className='section-title section-title--center'>My projects</h2>
                    <Inputsearch props={setProject} />
                    <div className='projects'>
                        {projects.length === 0 ? (<p className="noresult-message">There are no results matching with your search.</p>) : projects}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Projects