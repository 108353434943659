import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'

import navLinksjSON from './NavLinks.json'

function NavLinks({ hideMenu }) {
    const [links, setLink] = useState(navLinksjSON)

    return (
        <ul className='nav-links'>
            {links.map((navItem, i) =>
                <li key={i}>
                    <NavLink className='link' to={navItem.pathName} onClick={hideMenu}>{navItem.pageName}</NavLink>
                </li>
            )}
        </ul>
    )
}

export default NavLinks