import React from 'react'

import './Intro.css'

function Intro() {
    return (
        <div className='intro-text'>
            <h1>Welcome on Claudiu Design</h1>
            <h2>Claudiu Suhaianu</h2>
            <h3>Front-End Web Developer</h3>

            <a href='#aboutme' className='link'><i className="fa-solid fa-circle-down"></i> About me</a>
        </div>
    )
}

export default Intro