import React from 'react'

import './Inputsearch.css'

function Inputsearch({ props }) {
    return (
        <input type='text' placeholder='Search a project using a title or a tag...' className='search-input' onChange={e => props(e.target.value)}/>
    )
}

export default Inputsearch