import React from 'react'
import { Link } from 'react-router-dom'

import Copyright from './Copyright'
import './Footer.css'

function Footer() {
	const footerTech = [{
			'techIcon': 'fab fa-html5 html5-icon',
			'techName': 'HTML5'
		}, {
			'techIcon': 'fab fa-js js-icon',
			'techName': 'JS / jQuery'
		}, {
			'techIcon': 'fab fa-css3-alt css3-icon',
			'techName': 'CSS3'
		}, {
			'techIcon': 'fa-brands fa-sass sass-icon',
			'techName': 'SASS'
		}, {
			'techIcon': 'fab fa-react react-icon',
			'techName': 'ReactJS'
		}, {
			'techIcon': 'fa-brands fa-node-js node-icon',
			'techName': 'NodeJS'
		}]

	return (
		<div className='section-padding footer-padding bg--shape_up'>
			<div className='outter-page'>
				<div className='inner-page'>
					<div className='footer-flex'>
						<div className='footer-col'>
							<h3>Technologies used</h3>
							<div className='tech-names'>
								{footerTech.map((techData, i) => (
									<div className='tech-row' key={i}>
										<div className='tech-icon'>
											<i className={techData.techIcon}></i>
										</div>
										<span className='tech-name'>{techData.techName}</span>
									</div>
								))}
							</div>
						</div>
						<div className='footer-col'>
							<h3>Useful links</h3>
							<ul className='footer-links'>
								<li><Link to='/contact' className='link'>Contact</Link></li>
								<li><Link to='/privacy-policy' className='link'>Privacy Policy</Link></li>
							</ul>
						</div>
						<div className='footer-col'>
							<h3>Why do you need a website?</h3>
							<p>If you want to expand your own business at a large level, for sure you will need a website. It gives you integrity of your own trademark and access to the most clients.
								Just a simple small project can be a quick start for you.</p>
						</div>
					</div>
					
					<Copyright />
				</div>
			</div>
		</div>
	)
}

export default Footer