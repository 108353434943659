import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import NavLinks from './NavLinks'
import './Navbar.css'

function Navbar() {
    const [isClicked, setClick] = useState(false)
    const toggle = () => setClick(!isClicked)

    const checkWindowSize = () => {
        if(window.innerWidth >= 1024) {
            if(isClicked === true) {
                setClick(false)
                document.querySelector('html').style.overflow = 'visible'
            }
        } else if(window.innerWidth < 1024) {
            if(isClicked === true) {
                document.querySelector('html').style.overflow = 'hidden'
            } else {
                document.querySelector('html').style.overflow = 'visible'
            }
        }
    }
    window.addEventListener('click', checkWindowSize)
    window.addEventListener('resize', checkWindowSize)

    return (
        <div className='outter-page'>
            <div className='inner-page nav-flex'>
                <div className='nav-logo'>
                    <Link to='/' >
                        <img src='/assets/logo/claudiu_design_logo.svg' alt='Claudiu Design Website'/>
                    </Link>
                </div>
                <nav className={isClicked ? 'nav-menu active' : 'nav-menu'}>
                    <NavLinks hideMenu={toggle} />
                </nav>
                <div className='nav-toggle' onClick={toggle}>
                    <i className={isClicked ? 'fa-solid fa-close' : 'fa-solid fa-bars'}></i>
                </div>
            </div>
        </div>
    )
}

export default Navbar