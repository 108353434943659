import React from 'react'

import './Hero.css'
import Intro from './Intro'

function Hero() {
    return (
        <section className='hero-video'>
            <video src='/assets/hero_video.mp4' muted={true} loop={true} autoPlay={true} playsInline={true}></video>

            <Intro />
        </section>
    )
}

export default Hero