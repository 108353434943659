import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import CookieConsent from 'react-cookie-consent'

import './App.css'

import NavBar from './components/Navbar/Navbar'
import Footer from './components/Footer/Footer'

import Home from './pages/Home'
import Contact from './pages/Contact'
import PrivacyPolicy from './pages/PrivacyPolicy'
import NotFound from './pages/NotFound'
import ScrollToTop from './ScrollToTop'

function App() {
    return (
        <>
            <Router>
                <header className='bg--shape_down'>
                    <ScrollToTop />
                    <NavBar />
                </header>

                <main>
                    <Routes>
                        <Route exact path='/' element={<Home />} />
                        <Route exact path='/contact' element={<Contact />} />
                        <Route exact path='/privacy-policy' element={<PrivacyPolicy />} />
                        <Route exact path='*' element={<NotFound />} />
                    </Routes>
                </main>

                <footer>
                    <Footer />
                </footer>
            </Router>

            <CookieConsent
                enableDeclineButton
                flipButtons
                location='bottom'
                buttonText='I Understand'
                declineButtonText='Decline (optional)'
                cookieName='claudiudesign_cookie'
                style={{
                    background: '#0a121d',
                    padding: '5px',
                    boxShadow: '0 -5px 10px rgba(250, 45, 165, .3)'
                }}
                buttonStyle={{
                    color: '#fff',
                    background: 'linear-gradient(167deg, rgba(250, 45, 165, 1) 0%, rgba(125, 59, 177, 1) 100%)',
                    fontSize: '12px',
                    padding: '12px 20px',
                    margin: '0',
                    marginRight: '20px',
                    borderRadius: '6px'
                }}
                declineButtonStyle={{
                    color: '#fff',
                    background: 'linear-gradient(167deg, rgba(250, 45, 165, 1) 0%, rgba(125, 59, 177, 1) 100%)',
                    fontSize: '12px',
                    padding: '12px 20px',
                    margin: '0',
                    marginRight: '20px',
                    borderRadius: '6px'
                }}
                expires={365}
            >
                This website uses cookies to enhance the user experience.
            </CookieConsent>
        </>
    )
}

export default App