import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'

import config from '../../config.json'

import './ContactForm.css'

function ContactForm() {
    const [checked, setChecked] = useState(false)
    const [data, setData] = useState({ name: '', email: '', phone: '', message: '' })
    const [error, setError] = useState({ name: '', email: '', phone: '', message: '' })
    const [validation, setValidation] = useState({ name: null, email: null, phone: null, message: null })
    const [message, setMessage] = useState({ color: '', text: '' })

    const checkCheckbox = () => setChecked(!checked)

    const handleChange = (e) => {
        const nameReg = /^[a-zA-Z-ăâîșțĂÂÎȘȚ\s]+$/
        const emailReg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
        const phoneReg = /^[0-9]{3,16}$/
        const messageReg = /^[a-zA-Z0-9-ăâîșțĂÂÎȘȚ()/,!.;:+=@#$%^&*\s]+$/

        setData({ ...data, [e.target.name]: e.target.value })
        
        if(e.target.name === 'name') {
            if(e.target.value === '') {
                setError({ ...error, name: 'This field should not be empty.' })
                setValidation({ ...validation, name: false })
            } else if(!nameReg.test(e.target.value) && e.target.value !== '') {
                setError({ ...error, name: 'The full name should contain only alphabet and minus (-) symbol.' })
                setValidation({ ...validation, name: false })
            } else {
                setError({ ...error, name: '' })
                setValidation({ ...validation, name: true })
            }
        }
                
        if(e.target.name === 'email') {
            if(e.target.value === '') {
                setError({ ...error, email: 'This field should not be empty.' })
                setValidation({ ...validation, email: false })
            } else if(!emailReg.test(e.target.value) && e.target.value !== '') {
                setError({ ...error, email: 'The email address has an wrong format. It should be like noreply@domain.com' })
                setValidation({ ...validation, email: false })
            } else {
                setError({ ...error, email: '' })
                setValidation({ ...validation, email: true })
            }
        }
            
        if(e.target.name === 'phone') {
            if(e.target.value === '') {
                setError({ ...error, phone: 'This field should not be empty.' })
                setValidation({ ...validation, phone: false })
            } else if(e.target.value.length < 3 || e.target.value.length > 16) {
                setError({ ...error, phone: 'The phone number should have 3-16 numbers.' })
                setValidation({ ...validation, phone: false })
            } else if(!phoneReg.test(e.target.value) && e.target.value !== '') {
                setError({ ...error, phone: 'The phone number should contain only numbers.' })
                setValidation({ ...validation, phone: false })
            } else {
                setError({ ...error, phone: '' })
                setValidation({ ...validation, phone: true })
            }
        }

        if(e.target.name === 'message') {
            if(e.target.value === '') {
                setError({ ...error, message: 'This field should not be empty.' })
                setValidation({ ...validation, message: false })
            } else if(!messageReg.test(e.target.value) && e.target.value !== '') {
                setError({ ...error, message: 'The message should not contain special characters.' })
                setValidation({ ...validation, message: false })
            } else {
                setError({ ...error, message: '' })
                setValidation({ ...validation, message: true })
            }
        }
    }

    const sendEmail = async (e) => {
        e.preventDefault()

        if(validation.name === null || validation.name === false || validation.email === null || validation.email === false
        || validation.phone === null || validation.phone === false || validation.message === null || validation.message === false) {
            setMessage({ color: 'error-msg', text: 'One or more fields are not filled properly.' })
            return
        }

        const dataToServer = {
            name: data.name,
            email: data.email,
            phone: data.phone,
            message: data.message
        }

        await axios.post(config.app_url_production + '/' + config.path.main_path + '/' + config.version + '/' + config.path.contact, dataToServer)
        .then((res) => {
            setMessage({ color: 'success-msg', text: res.data.message })
            setChecked(false)
        })
        .catch((err) => {
            console.clear()
            if(err.response) {
                setMessage({ color: 'error-msg', text: err.response.data.message })
            }
        }) 

        setData({ name: '', email: '', phone: '', message: '' })
    }

    return (
        <div className='contact-box'>
            <div className='contact-map'>
                <iframe title="Claudiu Design Location" src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d27106.934111476323!2d26.095534720044174!3d44.427716531485046!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40b1f93abf3cad4f%3A0xac0632e37c9ca628!2sBucure%C8%99ti!5e0!3m2!1sro!2sro!4v1657562007312!5m2!1sro!2sro' allowFullScreen loading='lazy' referrerPolicy='no-referrer-when-downgrade'></iframe>
            </div>
            <div className='contact-form'>
                <h2 className='contact-title'>Contact Form</h2>
                <form onSubmit={sendEmail}>
                    <div className='form-row'>
                        <label>Full Name: *</label>
                        <input className={validation.name === false ? 'form-input red' : 'form-input'} type='text' name="name" value={data.name} onChange={handleChange} placeholder='Write your full name here...' required />
                        { error.name && <p className='error-msg'>{ error.name }</p> }
                    </div>

                    <div className='form-row multi-row'>
                        <div className='form-col'>
                            <label>E-Mail Address: *</label>
                            <input className={validation.email === false ? 'form-input red' : 'form-input'} type='email' name="email" value={data.email} onChange={handleChange} placeholder='Write your email address here...' required />
                            { error.email && <p className='error-msg'>{ error.email }</p> }
                        </div>

                        <div className='form-col'>
                            <label>Phone Number: *</label>
                            <input className={validation.phone === false ? 'form-input red' : 'form-input'} type='tel' name="phone" value={data.phone} onChange={handleChange} placeholder='Write your phone number here...' required />
                            { error.phone && <p className='error-msg'>{ error.phone }</p> }
                        </div>
                    </div>

                    <div className='form-row'>
                        <label>Your Message: *</label>
                        <textarea className={validation.message === false ? 'form-input form-textarea red' : 'form-input form-textarea'} name="message" value={data.message} onChange={handleChange} placeholder='Write your message here...' required></textarea>
                        { error.message && <p className='error-msg'>{ error.message }</p> }
                    </div>

                    <div className='form-row'>
                        <label className="custom-check">* I have read and I'm agree with <Link to='/privacy-policy'>Privacy Policy</Link>.
                            <input type="checkbox" checked={checked} onChange={checkCheckbox} required />
                            <span className="checkmark"></span>
                        </label>
                    </div>

                    <div className='form-row'>
                        { message.text &&
                        <div className={'message ' + message.color}>
                            <p className='message--text'>{message.text}</p>
                        </div>
                        }
                    </div>

                    <div className='form-row'>
                        <input className='submit-btn' type='submit' value='Send' disabled={checked ? '' : true} />
                    </div>
                </form>
            </div>
        </div>
    )
}

export default ContactForm