import React from 'react'

import './Copyright.css'

function Copyright() {
    return (
        <>
            <div className='copyright'>
                <div className='copyright-text'>
                    Copyright <span className='developer'>Claudiu Design</span> @ 2021 - {new Date().getFullYear()}. All rights reserved.
                </div>
                <div className='made-with'>
                    Made with <i className="fa-solid fa-heart"></i> in <i className="fa-brands fa-react"></i> ReactJS and <i className="fa-brands fa-node-js"></i> NodeJS
                </div>
            </div>
        </>
    )
}

export default Copyright