import React from 'react'

import Hero from '../components/Hero/Hero'
import Aboutme from '../components/Aboutme/Aboutme'
import Boxcards from '../components/Boxcards/Boxcards'
import Projects from '../components/Projects/Projects'

function Home() {
	return (
		<>
			<Hero />
			<Aboutme />
			<Boxcards />
			<Projects />
		</>
	)
}

export default Home