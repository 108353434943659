import React from 'react'

import './Icon.css'

function Icon( { iconType, iconText } ) {
    return (
        <img src={iconType} alt={iconText}/>
    )
}

export default Icon