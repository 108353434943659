import React from 'react'

import InnerBanner from '../components/InnerBanner/InnerBanner'

function NotFound() {
    const notFoundTitle = 'Page Not Found'
    const banner = '/assets/banners/default-inner-banner.jpg'

    return (
        <>
            <InnerBanner title={notFoundTitle} bannerImg={banner} />
            <div className='outter-page'>
                <div className='not-found-message'>404 Not Found</div>
            </div>
        </>
    )
}

export default NotFound