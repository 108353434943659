import React from 'react'

import InnerBanner from '../components/InnerBanner/InnerBanner'
import ContactForm from '../components/ContactForm/ContactForm'
import Icon from '../components/Icon/Icon'

function Contact() {
    const contactTitle = 'Contact'
    const banner = '/assets/banners/default-inner-banner.jpg'
    const mailIcon = '/assets/icons/mail.svg'
    const phoneIcon = '/assets/icons/phone.svg'
    const mapIcon = '/assets/icons/maps.svg'

	return (
        <>
            <InnerBanner title={contactTitle} bannerImg={banner} />
            <div className='outter-page'>
                <div className='inner-page'>
                    <div className='grid-icons'>
                        <div className='icon-box'>
                            <div className='icon-img'>
                                <Icon iconType={mapIcon} iconText="Claudiu Design Address" />
                            </div>
                            <h2 className='icon-title'>Address</h2>
                            <p className='icon-text'>Bucharest / Romania</p>
                        </div>
                        <div className='icon-box'>
                            <div className='icon-img'>
                                <Icon iconType={mailIcon} iconText="Claudiu Design Email" />
                            </div>
                            <h2 className='icon-title'>E-Mail Address</h2>
                            <p className='icon-text'>contact@claudiudesign.ro</p>
                        </div>
                        <div className='icon-box'>
                            <div className='icon-img'>
                                <Icon iconType={phoneIcon} iconText="Claudiu Design Phone" />
                            </div>
                            <h2 className='icon-title'>Phone Number</h2>
                            <p className='icon-text'>+40 761 499 881</p>
                        </div>
                    </div>
                    
                    <ContactForm />
                </div>
            </div>
        </>
	)
}

export default Contact