import React from 'react'

import './Aboutme.css'

function Aboutme() {
    const myAge = () => {
        const bornAge = new Date('1998-11-20')
        const now = new Date()
        
        let age = now.getFullYear() - bornAge.getFullYear()
        const monthDifference = now.getMonth() - bornAge.getMonth()
        const dayDifference = now.getDate() - bornAge.getDate()
    
        if (monthDifference < 0 || (monthDifference === 0 && dayDifference < 0)) {
            age--
        }
    
        return age
    }
    
    const myCurrentAge = myAge()

    return (
        <section className='section-padding bg--shape_video aboutme-section' id='aboutme'>
            <div className='outter-page'>
                <div className='inner-page'>
                    <div className='aboutme-container'>
                        <div className='aboutme-img-container'>
                            <div className='aboutme-img'>
                                <img src='/assets/myself_pic.png' alt='Claudiu Suhaianu' />
                            </div>
                        </div>
                        <div className='aboutme-description'>
                            <h2 className='section-title'>About me...</h2>
                            <p className='description'>Hi! My name is <b>Suhăianu Ionuț-Florentin-Claudiu</b>, I'm <b>{myCurrentAge} years</b> old and I'm from <b>Bucharest - Romania</b>.</p>
                            <p className='description'>I am a graduate of University of Bucharest at Databases and Software Techologies and i have a master degree in this field.</p>
                            <p className='description'>My main skills are <b>HTML5</b>, <b>CSS3 (SASS)</b>, <b>JavaScript (with jQuery library)</b>, <b>ReactJS</b>, <b>NodeJS</b> and databases, often using <b>MySQL</b>. Also, i know a bit of <b>PHP</b>. 
                            My desire is to develop myself professionally and to learn new things. If you want to know more, you can read on the cards below where my skills are shown.</p>
                            <p className='description'>I have a collection of personal projects that can be seen in the bottom of this website. I am a hard worker,
                            and everyday I strive to learn and to improve my problem solving skills and all the technical skills I need for my Web Development.</p>

                            <div className='aboutme-btns'>
                                <a href='/assets/CV_Suhaianu_Claudiu.pdf' className='aboutme-resume' rel='noreferrer' target='_blank'>Resume <i className="fas fa-download icon"></i></a>
                                <div className='aboutme-social-media'>
                                    <a href="https://www.facebook.com/claudiuu.florentin/" title="Facebook" target="_blank" rel='noreferrer'><i className="fab fa-facebook-f icon"></i></a>
                                    <a href="https://www.instagram.com/claudiuu_florentin/" title="Instagram" target="_blank" rel='noreferrer'><i className="fab fa-instagram icon"></i></a>
                                    <a href="https://github.com/ClaudiuFlorentin" title="GitHub" target="_blank" rel='noreferrer'><i className="fab fa-github icon"></i></a>
                                    <a href="https://www.linkedin.com/in/suhaianu-ionut-florentin-claudiu-72868b218/" title="LinkedIn" rel='noreferrer'><i className="fab fa-linkedin icon"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Aboutme