import React from 'react'

import './InnerBanner.css'

function InnerBanner( { title, bannerImg } ) {
    const bannerStyle = {
        position: 'relative',
        backgroundImage: `url(${bannerImg})`,
        backgroundPosition: 'center center',
        backgroundSize: 'cover',
        height: '500px',
        marginBottom: '30px'
    }

    return (
        <div className='inner-banner' style={bannerStyle}>
            <div className='outter-page'>
                <div className='inner-page'>
                    <h1 className='page-title'>{title}</h1>
                </div>
            </div>
        </div>
    )
}

export default InnerBanner